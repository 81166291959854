import Visualizer from "./webgl/visualizer";

export default class particleBuilder {
  constructor(container, options = {}) {
	let settings = {
	  container: container ?? null,
	  images: options.images ?? null,
	  screen: false,
	  color: options.color ?? "#ffffff"
	};
	this.options = Object.assign({}, settings, options);
	this.initVisualizer();
	this.addListeners();
	this.animate();
	this.resize();
  }
  initVisualizer() {
	this.visualizer = new Visualizer(this);
	this.canvasContainer = document.querySelector(this.options.container)
	this.visualizer.renderer.domElement.classList.add('webgl-canvas')
	if(this.options.screen){
		this.webgl = document.createElement('div')
		this.webgl.classList = 'webgl'
		document.querySelector(this.options.container).prepend(this.webgl)
		this.overlayScreen = document.createElement('div')
		this.overlayScreen.classList = 'webgl webgl-screen'
		this.overlayScreen.style.cssText = `background-image: linear-gradient(to bottom, rgba(20, 26, 22, 0) 10%, rgba(20, 26, 22, 1) 100%);  position: absolute; top: 0; height: 100%; width: 100%; z-index: 1;`
		this.webgl.prepend(this.overlayScreen)
		this.webgl.prepend(this.visualizer.renderer.domElement)
	}else{
		this.visualizer.renderer.domElement.classList.add('webgl')
		document.querySelector(this.options.container).prepend(this.visualizer.renderer.domElement)
	}
  }
  addListeners() {
	this.handlerAnimate = this.animate.bind(this);

	window.addEventListener("resize", this.resize.bind(this));

	this.el = this.visualizer.renderer.domElement;
	// el.addEventListener("click", this.click.bind(this));
  }
  animate() {
	this.update();
	this.draw();

	this.raf = requestAnimationFrame(this.handlerAnimate);
  }
  // ---------------------------------------------------------------------------------------------
  // PUBLIC
  // ---------------------------------------------------------------------------------------------

  update() {
	if (this.visualizer) this.visualizer.update();
  }

  draw() {
	if (this.visualizer) this.visualizer.draw();
  }

  // ---------------------------------------------------------------------------------------------
  // EVENT HANDLERS
  // ---------------------------------------------------------------------------------------------

  resize() {
	if (this.visualizer) this.visualizer.resize();
  }

  click(e) {
	this.visualizer.next();
  }
}
