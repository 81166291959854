// import EventEmitter from "events";
// import emit from "emit";
// import EventEmitter from "eventemitter"
import * as THREE from "three";
import browser from "browser-detect";
import { passiveEvent } from "../_event.util";
// console.log(EventEmitter)

export default class Controls{
  // get enabled() {
	// return this._enabled;
  // }

  constructor(camera, el) {
	// super();

	this.event = {
		interactive: {}
	}

	this.camera = camera;
	this.el = el || window;

	this.plane = new THREE.Plane();
	this.raycaster = new THREE.Raycaster();

	this.mouse = new THREE.Vector2();
	this.offset = new THREE.Vector3();
	this.intersection = new THREE.Vector3();

	this.objects = [];
	this.hovered = null;
	this.selected = null;

	this.isDown = false;

	this.browser = browser();

	this.enable();
  }

  enable() {
	if (this.enabled) return;
	this.addListeners();
	this._enabled = true;
  }

  disable() {
	if (!this.enabled) return;
	this.removeListeners();
	this._enabled = false;
  }

  addListeners() {
	this.handlerDown = this.onDown.bind(this);
	this.handlerMove = this.onMove.bind(this);
	this.handlerUp = this.onUp.bind(this);
	this.handlerLeave = this.onLeave.bind(this);

	if (this.browser.mobile) {
	  this.el.addEventListener("touchstart", this.handlerDown, passiveEvent);
	  this.el.addEventListener("touchmove", this.handlerMove, passiveEvent);
	  this.el.addEventListener("touchend", this.handlerUp, passiveEvent);
	} else {
	  this.el.addEventListener("mousedown", this.handlerDown);
	  this.el.addEventListener("mousemove", this.handlerMove);
	  // document.body.addEventListener("mousemove", this.handlerMove);
	  this.el.addEventListener("mouseup", this.handlerUp);
	  this.el.addEventListener("mouseleave", this.handlerLeave);
	}
  }

  removeListeners() {
	if (this.browser.mobile) {
	  this.el.removeEventListener("touchstart", this.handlerDown);
	  this.el.removeEventListener("touchmove", this.handlerMove);
	  this.el.removeEventListener("touchend", this.handlerUp);
	} else {
	  this.el.removeEventListener("mousedown", this.handlerDown);
	  this.el.removeEventListener("mousemove", this.handlerMove);
	  document.body.removeEventListener("mousemove", this.handlerMove);
	  this.el.removeEventListener("mouseup", this.handlerUp);
	  this.el.removeEventListener("mouseleave", this.handlerLeave);
	}
  }

  resize(x, y, width, height) {
	if (x || y || width || height) {
	  this.rect = { x, y, width, height };
	} else if (this.el === window) {
	  this.rect = {
		x: 0,
		y: 0,
		width: window.innerWidth,
		height: window.innerHeight
	  };
	} else {
	  this.rect = this.el.getBoundingClientRect();
	}
  }

  onMove(e) {

	  if ( window.fw3jspause ) {
			return;
		}

	  const that = this
	const t = e.touches ? e.touches[0] : e;
	const touch = { x: t.clientX, y: t.clientY };

	this.mouse.x = ((touch.x + this.rect.x) / this.rect.width) * 2 - 1;
	this.mouse.y = -((touch.y + this.rect.y) / this.rect.height) * 2 + 1;

	this.raycaster.setFromCamera(this.mouse, this.camera);

	/*
		// is dragging
		if (this.selected && this.isDown) {
			if (this.raycaster.ray.intersectPlane(this.plane, this.intersection)) {
				this.emit('interactive-drag', { object: this.selected, position: this.intersection.sub(this.offset) });
			}
			return;
		}
		*/

	const intersects = this.raycaster.intersectObjects(this.objects, true);

	if (intersects.length > 0) {

	  const object = intersects[0].object;
	  that.intersectionData = intersects[0];

	  this.plane.setFromNormalAndCoplanarPoint(
		this.camera.getWorldDirection(this.plane.normal),
		object.position
	  );


	  if (this.hovered !== object) {
		this.event.interactive.out = new Event("interactive-out", { object: this.hovered });
		document.body.dispatchEvent(this.event.interactive.out)
		this.event.interactive.over = new Event("interactive-over", {
			object,
			intersectionData: that.intersectionData
		});
		document.body.dispatchEvent(this.event.interactive.over)
		this.hovered = object;
	  } else {
		this.event.interactive.move = new Event("interactive-move", {
			object,
			intersectionData: that.intersectionData
		});
		document.body.dispatchEvent(this.event.interactive.move)
	  }

	} else {
	  this.intersectionData = null;

	  if (this.hovered !== null) {
		this.event.interactive.out = new Event("interactive-out", { object: this.hovered });
		document.body.dispatchEvent(this.event.interactive.out)
		this.hovered = null;
	  }
	}
  }

  onDown(e) {
	this.isDown = true;
	this.onMove(e);

	this.event.interactive.down = new Event("interactive-down", {
	  object: this.hovered,
	  previous: this.selected,
	  intersectionData: this.intersectionData
	});
	document.body.dispatchEvent(this.event.interactive.down)
	this.selected = this.hovered;

	if (this.selected) {
	  if (this.raycaster.ray.intersectPlane(this.plane, this.intersection)) {
		this.offset.copy(this.intersection).sub(this.selected.position);
	  }
	}
  }

  onUp(e) {
	this.isDown = false;

	this.event.interactive.up = new Event("interactive-up", { object: this.hovered });
	document.body.dispatchEvent(this.event.interactive.up)
  }

  onLeave(e) {
	this.onUp(e);

	this.event.interactive.out = new Event("interactive-out", { object: this.hovered });
	document.body.dispatchEvent(this.event.interactive.out)
	this.hovered = null;
  }
}
