import * as THREE from "three";
import TouchTexture from "./touchtexture";
import gsap from "gsap";
// import glslify from "glslify";
// import glsl from "glslify";
// const fragment = glsl.file('./shaders/fragment.glsl')
// const vertex = glsl.file('./shaders/vertext.glsl')
// glsl.file('glsl-noise/simplex/2d.glsl')
// import "raw-loader!glslify-loader!glsl-noise/simplex/2d.glsl";

export default class Particles {
  constructor(app) {
	this.app = app.app;
	this.visualizer = app;
	this.container = new THREE.Object3D();
  }
  init(src) {
	const loader = new THREE.TextureLoader();
	loader.load(src, (texture) => {
	  this.texture = texture;
	  this.texture.minFilter = THREE.LinearFilter;
	  this.texture.magFilter = THREE.LinearFilter;
	  this.texture.format = THREE.RGBFormat;

	  this.width = texture.image.width;
	  this.height = texture.image.height;

	  this.initPoints(true);
	  this.initHitArea();
	  this.initTouch();
	  this.resize();
	  this.show();
	});
  }
  initPoints(discard) {
	this.numPoints = this.width * this.height;

	let numVisible = this.numPoints;
	let threshold = 0;
	let originalColors;

	if (discard) {
	  // discard pixels darker than threshold #22
	  numVisible = 0;
	  threshold = 44;

	  const img = this.texture.image;
	  const canvas = document.createElement("canvas");
	  const ctx = canvas.getContext("2d");

	  canvas.width = this.width;
	  canvas.height = this.height;
	  ctx.scale(1, -1);
	  // console.log(img);
	  ctx.drawImage(img, 0, 0, this.width, this.height * -1);

	  const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
	  originalColors = Float32Array.from(imgData.data);

	  for (let i = 0; i < this.numPoints; i++) {
		if (originalColors[i * 4 + 0] > threshold) numVisible++;
	  }
	}

	this.color = this.hexToGL(this.app.options.color);
	this.uniforms = {
	  uTime: { value: 0 },
	  uRandom: { value: 1.0 },
	  uDepth: { value: 2.0 },
	  uSize: { value: 0.0 },
	  uTextureSize: { value: new THREE.Vector2(this.width, this.height) },
	  hexColor: { value: this.app.options.color },
	  uColor: {
		value: new THREE.Vector4(
		  this.color[0],
		  this.color[1],
		  this.color[2],
		  1.0
		)
	  },
	  uTexture: { value: this.texture },
	  uTouch: { value: null }
	};

	this.material = new THREE.RawShaderMaterial({
	  uniforms: this.uniforms,
	  vertexShader: `
	  precision highp float;

	  attribute float pindex;
	  attribute vec3 position;
	  attribute vec3 offset;
	  attribute vec2 uv;
	  attribute float angle;

	  uniform mat4 modelViewMatrix;
	  uniform mat4 projectionMatrix;

	  uniform float uTime;
	  uniform float uRandom;
	  uniform float uDepth;
	  uniform float uSize;
	  uniform vec2 uTextureSize;
	  uniform sampler2D uTexture;
	  uniform sampler2D uTouch;

	  varying vec2 vPUv;
	  varying vec2 vUv;

		vec3 mod289(vec3 x) {
		  return x - floor(x * (1.0 / 289.0)) * 289.0;
		}

		vec2 mod289(vec2 x) {
		  return x - floor(x * (1.0 / 289.0)) * 289.0;
		}

		vec3 permute(vec3 x) {
		  return mod289(((x*34.0)+1.0)*x);
		}

		float snoise(vec2 v)
		  {
		  const vec4 C = vec4(0.211324865405187,  // (3.0-sqrt(3.0))/6.0
							  0.366025403784439,  // 0.5*(sqrt(3.0)-1.0)
							 -0.577350269189626,  // -1.0 + 2.0 * C.x
							  0.024390243902439); // 1.0 / 41.0
		// First corner
		  vec2 i  = floor(v + dot(v, C.yy) );
		  vec2 x0 = v -   i + dot(i, C.xx);

		// Other corners
		  vec2 i1;
		  //i1.x = step( x0.y, x0.x ); // x0.x > x0.y ? 1.0 : 0.0
		  //i1.y = 1.0 - i1.x;
		  i1 = (x0.x > x0.y) ? vec2(1.0, 0.0) : vec2(0.0, 1.0);
		  // x0 = x0 - 0.0 + 0.0 * C.xx ;
		  // x1 = x0 - i1 + 1.0 * C.xx ;
		  // x2 = x0 - 1.0 + 2.0 * C.xx ;
		  vec4 x12 = x0.xyxy + C.xxzz;
		  x12.xy -= i1;

		// Permutations
		  i = mod289(i); // Avoid truncation effects in permutation
		  vec3 p = permute( permute( i.y + vec3(0.0, i1.y, 1.0 ))
			+ i.x + vec3(0.0, i1.x, 1.0 ));

		  vec3 m = max(0.5 - vec3(dot(x0,x0), dot(x12.xy,x12.xy), dot(x12.zw,x12.zw)), 0.0);
		  m = m*m ;
		  m = m*m ;

		// Gradients: 41 points uniformly over a line, mapped onto a diamond.
		// The ring size 17*17 = 289 is close to a multiple of 41 (41*7 = 287)

		  vec3 x = 2.0 * fract(p * C.www) - 1.0;
		  vec3 h = abs(x) - 0.5;
		  vec3 ox = floor(x + 0.5);
		  vec3 a0 = x - ox;

		// Normalise gradients implicitly by scaling m
		// Approximation of: m *= inversesqrt( a0*a0 + h*h );
		  m *= 1.79284291400159 - 0.85373472095314 * ( a0*a0 + h*h );

		// Compute final noise value at P
		  vec3 g;
		  g.x  = a0.x  * x0.x  + h.x  * x0.y;
		  g.yz = a0.yz * x12.xz + h.yz * x12.yw;
		  return 130.0 * dot(m, g);
		}

	  float random(float n) {
		  return fract(sin(n) * 43758.5453123);
	  }

	  void main() {
		  vUv = uv;

		  // particle uv
		  vec2 puv = offset.xy / uTextureSize;
		  vPUv = puv;

		  // pixel color
		  vec4 colA = texture2D(uTexture, puv);
		  float colorized = colA.r * 0.11 + colA.g * 0.71 + colA.b * 0.1;

		  // displacement
		  vec3 displaced = offset;
		  // randomise
		  displaced.xy += vec2(random(pindex) - 0.5, random(offset.x + pindex) - 0.5) * uRandom;
		  float rndz = (random(pindex) + snoise(vec2(pindex * 0.1, uTime * 0.1)));
		  displaced.z += rndz * (random(pindex) * 2.0 * uDepth);
		  // center
		  displaced.xy -= uTextureSize * 0.5;

		  // touch
		  float t = texture2D(uTouch, puv).r;
		  displaced.z += t * 20.0 * rndz;
		  displaced.x += cos(angle) * t * 20.0 * rndz;
		  displaced.y += sin(angle) * t * 20.0 * rndz;

		  // particle size
		  float psize = (snoise(vec2(uTime, pindex) * 0.5) + 2.0);
		  psize *= max(colorized, 0.2);
		  psize *= uSize;

		  // final position
		  vec4 mvPosition = modelViewMatrix * vec4(displaced, 1.0);
		  mvPosition.xyz += position * psize;
		  vec4 finalPosition = projectionMatrix * mvPosition;

		  gl_Position = finalPosition;
	  }
`,
	  fragmentShader: `
	  precision highp float;

	  uniform sampler2D uTexture;

	  uniform vec4 uColor;
	  varying vec2 vPUv;
	  varying vec2 vUv;

	  void main() {
		  vec4 color = uColor;
		  vec2 uv = vUv;
		  vec2 puv = vPUv;

		  // pixel color if needed
		  vec4 colA = texture2D(uTexture, puv);

		  // circle
		  float border = 0.3;
		  float radius = 0.5;
		  float dist = radius - distance(uv, vec2(0.5));
		  float t = smoothstep(0.0, border, dist);

		  // final color
		  color.a = t;

		  gl_FragColor = color;
	  }`,
	  depthTest: false,
	  transparent: true
	  // blending: THREE.AdditiveBlending
	});

	const geometry = new THREE.InstancedBufferGeometry();

	// positions
	const positions = new THREE.BufferAttribute(new Float32Array(4 * 3), 3);
	positions.setXYZ(0, -0.5, 0.5, 0.0);
	positions.setXYZ(1, 0.5, 0.5, 0.0);
	positions.setXYZ(2, -0.5, -0.5, 0.0);
	positions.setXYZ(3, 0.5, -0.5, 0.0);
	geometry.setAttribute("position", positions);

	// uvs
	const uvs = new THREE.BufferAttribute(new Float32Array(4 * 2), 2);
	uvs.setXYZ(0, 0.0, 0.0);
	uvs.setXYZ(1, 1.0, 0.0);
	uvs.setXYZ(2, 0.0, 1.0);
	uvs.setXYZ(3, 1.0, 1.0);
	geometry.setAttribute("uv", uvs);

	// index
	geometry.setIndex(
	  new THREE.BufferAttribute(new Uint16Array([0, 2, 1, 2, 3, 1]), 1)
	);

	const indices = new Uint16Array(numVisible);
	const offsets = new Float32Array(numVisible * 3);
	const angles = new Float32Array(numVisible);

	for (let i = 0, j = 0; i < this.numPoints; i++) {
	  if (discard && originalColors[i * 4 + 0] <= threshold) continue;

	  offsets[j * 3 + 0] = i % this.width;
	  offsets[j * 3 + 1] = Math.floor(i / this.width);

	  indices[j] = i;

	  angles[j] = Math.random() * Math.PI;

	  j++;
	}

	geometry.setAttribute(
	  "pindex",
	  new THREE.InstancedBufferAttribute(indices, 1, false)
	);
	geometry.setAttribute(
	  "offset",
	  new THREE.InstancedBufferAttribute(offsets, 3, false)
	);
	geometry.setAttribute(
	  "angle",
	  new THREE.InstancedBufferAttribute(angles, 1, false)
	);

	this.object3D = new THREE.Mesh(geometry, this.material);
	this.container.add(this.object3D);
  }

  hexToGL(hexStr) {
	//check if valid hex value
	if (/^#([0-9A-F]{3}){1,2}$/i.test(hexStr)) {
	  let col = new THREE.Color(hexStr);
	  let out = col.toArray().map((x) => {
		//to fixed 3
		let conv = Math.round(x * 1000) / 1000;
		//append missing periods
		if (conv.toString().indexOf(".") === -1) conv += ".";
		return conv;
	  });
	  return out;
	} else {
	  return "";
	}
  }

  initTouch() {
	// create only once
	if (!this.touch) this.touch = new TouchTexture(this);
	this.object3D.material.uniforms.uTouch.value = this.touch.texture;
  }
  initHitArea() {
	const geometry = new THREE.PlaneGeometry(this.width, this.height, 1, 1);
	const material = new THREE.MeshBasicMaterial({
	  color: 0xffffff,
	  wireframe: true,
	  depthTest: false
	});
	material.visible = false;
	this.hitArea = new THREE.Mesh(geometry, material);
	this.container.add(this.hitArea);
  }
  addListeners() {
	this.handlerInteractiveMove = this.onInteractiveMove.bind(this);

	document.body.addEventListener(
	  "interactive-move",
	  this.handlerInteractiveMove
	);
	this.visualizer.interactive.objects.push(this.hitArea);
	this.visualizer.interactive.enable();
  }

  removeListeners() {
	document.body.removeEventListener(
	  "interactive-move",
	  this.handlerInteractiveMove
	);

	const index = this.visualizer.interactive.objects.findIndex(
	  (obj) => obj === this.hitArea
	);
	this.visualizer.interactive.objects.splice(index, 1);
	// this.visualizer.interactive.disable();
  }

  // ---------------------------------------------------------------------------------------------
  // PUBLIC
  // ---------------------------------------------------------------------------------------------

  update(delta) {
	if (!this.object3D) return;
	if (this.touch) this.touch.update();

	this.object3D.material.uniforms.uTime.value += delta;
  }

  show(time = 1.0) {
	gsap.fromTo(
	  this.object3D.material.uniforms.uSize,
	  time,
	  { value: 0 },
	  { value: 0.65 }
	);
	gsap.fromTo(
	  this.object3D.material.uniforms.uRandom,
	  time,
	  { value: -40.0 },
	  { value: 1.0 }
	);
	gsap.fromTo(
	  this.object3D.material.uniforms.uDepth,
	  time * 1.5,
	  { value: -80.0 },
	  { value: 1.0 }
	);
	this.addListeners();
  }

  hide(_destroy, time = 1.0) {
	// return new Promise((resolve, reject) => {
	gsap.to(this.object3D.material.uniforms.uRandom, time, {
	  value: -40.0,
	  ease: "sine.in",
	  onComplete: () => {
		if (_destroy) this.destroy();
	  }
	});
	gsap.to(this.object3D.material.uniforms.uDepth, time * 1.5, {
	  value: -80.0,
	  ease: "sine.in"
	});
	gsap.to(this.object3D.material.uniforms.uSize, time, {
	  value: 0.0,
	  delay: 0.8
	});

	this.removeListeners();
	// });
  }

  destroy() {
	if (!this.object3D) return;

	this.object3D.parent.remove(this.object3D);
	this.object3D.geometry.dispose();
	this.object3D.material.dispose();
	this.object3D = null;

	if (!this.hitArea) return;

	this.hitArea.parent.remove(this.hitArea);
	this.hitArea.geometry.dispose();
	this.hitArea.material.dispose();
	this.hitArea = null;
  }

  // ---------------------------------------------------------------------------------------------
  // EVENT HANDLERS
  // ---------------------------------------------------------------------------------------------

  resize() {
	if (!this.object3D) return;

	const scale = this.visualizer.fovHeight / this.height;
	this.object3D.scale.set(scale, scale, 1);
	this.hitArea.scale.set(scale, scale, 1);
  }

  onInteractiveMove(e) {
	// console.log(this.app.visualizer.interactive.intersectionData.uv)
	const uv = this.app.visualizer.interactive.intersectionData.uv;
	if (this.touch) this.touch.addTouch(uv);
  }
}
