import * as THREE from "three";
import { OrbitControls } from "OrbitControls";
import Controls from "./controls";
import Particles from "./particles";
//const glslify = require("glslify");

export default class Visualizer {
  constructor(app) {
	this.app = app;
	this.init();
	this.initParticles();
	this.initControls();
	// const rnd = ~~(Math.random() * this.app.options.images.length);
	// console.log(rnd);
	// this.goto(0);
  }
  init() {
	this.scene = new THREE.Scene();
	this.camera = new THREE.PerspectiveCamera(
	  50,
	  window.innerWidth / window.innerHeight,
	  1,
	  10000
	);
	this.camera.position.z = 300;
	// this.camera.position.set(0, 50, 150);
	this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
	this.clock = new THREE.Clock(true);
	this.orbitControls = new OrbitControls(
	  this.camera,
	  this.renderer.domElement
	);
	this.orbitControls.enabled = false;
	this.orbitControls.update();
	// this.mouseMovement();
  }
  initControls() {
	this.interactive = new Controls(this.camera);
	// this.interactive.addListeners()
  }
  initParticles() {
	this.particles = new Particles(this);
	this.scene.add(this.particles.container);
	// this.particles.init(this.app.options.images[0]);
	// this.animate();
  }
  mouseMovement() {
	this.mouseX = 3;
	this.lastMouseX = 3;
	this.lastMouseY = 65;
	this.lastScale = 0;
	this.tiltFx = {
	  body: document.body,
	  docEl: document.documentElement,
	  getMousePos: (e, docScrolls) => {
		let posx = 0;
		let posy = 0;
		if (!e) {
		  e = window.event;
		}
		if (e.pageX || e.pageY) {
		  posx = e.pageX;
		  posy = e.pageY;
		} else if (e.clientX || e.clientY) {
		  posx = e.clientX + docScrolls.left;
		  posy = e.clientY + docScrolls.top;
		}
		return { x: posx, y: posy };
	  },
	  lerp: (a, b, n) => (1 - n) * a + n * b,
	  lineEq: (y2, y1, x2, x1, currentVal) => {
		let m = (y2 - y1) / (x2 - x1);
		let b = y1 - m * x1;
		return m * currentVal + b;
	  }
	};

	this.docheight = Math.max(
	  this.tiltFx.body.scrollHeight,
	  this.tiltFx.body.offsetHeight,
	  this.tiltFx.docEl.clientHeight,
	  this.tiltFx.docEl.scrollHeight,
	  this.tiltFx.docEl.offsetHeight
	);

	this.requestId = requestAnimationFrame(() => this.tilt());

	window.addEventListener("mousemove", (ev) => {
	  const docScrolls = {
		left: this.tiltFx.body.scrollLeft + this.tiltFx.docEl.scrollLeft,
		top: this.tiltFx.body.scrollTop + this.tiltFx.docEl.scrollTop
	  };
	  const mp = this.tiltFx.getMousePos(ev, docScrolls);
	  this.mouseX = mp.x - docScrolls.left;
	});

	window.addEventListener(
	  "resize",
	  () =>
		(this.docheight = Math.max(
		  this.tiltFx.body.scrollHeight,
		  this.tiltFx.body.offsetHeight,
		  this.tiltFx.docEl.clientHeight,
		  this.tiltFx.docEl.scrollHeight,
		  this.tiltFx.docEl.offsetHeight
		))
	);
  }
  tilt() {
	this.lastMouseX = this.tiltFx.lerp(
	  this.lastMouseX,
	  this.tiltFx.lineEq(12, 0, window.innerWidth, 0, this.mouseX),
	  0.05
	);
	const newScrollingPos = window.pageYOffset;
	this.lastMouseY = this.tiltFx.lerp(
	  this.lastMouseY,
	  this.tiltFx.lineEq(0, 65, this.docheight, 0, newScrollingPos),
	  0.05
	);
	this.lastScale = this.tiltFx.lerp(
	  this.lastScale,
	  this.tiltFx.lineEq(0, 158, this.docheight, 0, newScrollingPos),
	  0.05
	);
	this.camera.position.set(this.lastMouseX, this.lastMouseY, this.lastScale);
	this.requestId = requestAnimationFrame(() => this.tilt());
  }
  // ---------------------------------------------------------------------------------------------
  // PUBLIC
  // ---------------------------------------------------------------------------------------------

  update() {
	const delta = this.clock.getDelta();

	if (this.particles) this.particles.update(delta);
  }

  draw() {
  	if ( window.fw3jspause ) {
		  return;
  	}

	this.renderer.render(this.scene, this.camera);
  }

  goto(index) {
	// init next
	if (this.currSample == null)
	  this.particles.init(this.app.options.images[index]);
	// hide curr then init next
	else {
	  // this.particles.hide(true).then(() => {
	  this.particles.hide();
	  this.particles.init(this.app.options.images[index]);
	  // });
	}

	this.currSample = index;
  }

  next() {
	if (this.currSample < this.app.options.images.length - 1)
	  this.goto(this.currSample + 1);
	else this.goto(0);
  }

  // ---------------------------------------------------------------------------------------------
  // EVENT HANDLERS
  // ---------------------------------------------------------------------------------------------

  resize() {
	if (!this.renderer) return;
	this.camera.aspect = window.innerWidth / window.innerHeight;
	this.camera.updateProjectionMatrix();

	this.fovHeight =
	  2 *
	  Math.tan((this.camera.fov * Math.PI) / 180 / 2) *
	  this.camera.position.z;

	this.renderer.setSize(window.innerWidth, window.innerHeight);

	if (this.interactive) this.interactive.resize();
	if (this.particles) this.particles.resize();
  }
}
